@import '../../assets/variables.scss';

.vehicleTransportCalculator {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -8px;
    gap: 71px;

    @media (max-width: 1600px) {
        transform: scale(0.85) translateY(-52px);
    }

    @media (max-width: 1050px) {
        transform: unset;
    }

    .mainContent {
        display: flex;
        gap: 213px;


        @media (max-width: 1050px) {
            width: 100%;
            flex-direction: column;
            gap: 57px;
            background-image: url('../../assets/images/Guide/calculatorBackground.png');
            background-position: center -140px;
            background-repeat: no-repeat;
            background-size: cover;
        }


        .form {
            display: flex;
            flex-direction: column;
            gap: 41px;
            margin-top: 30px;

            @media (max-width: 1050px) {
                align-items: center;
            }

            @media (max-width: 525px) {
                gap: 14px;
            }

            h2 {
                font-family: $second-font;
                font-size: 36px;
                font-weight: bold;
                line-height: 100%;
                text-transform: uppercase;
                white-space: nowrap;

                @media (max-width: 525px) {
                    font-size: 22px;
                }
            }

            .inputBlock {
                display: flex;
                flex-direction: column;
                gap: 25px;

                @media (max-width: 525px) {
                    gap: 14px;
                }

                input[type=text] {
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 100%;
                    display: flex;
                    width: 351px;
                    padding: 11.5px 52px;
                    align-items: center;
                    border-radius: 10px;
                    border: 1px solid #FF5408;
                    box-shadow: 0px 0px 15px 0px rgba(142, 142, 142, 0.30) inset;
                    outline: none;

                    @media (max-width: 525px) {
                        font-size: 11px;
                        width: 296px;
                        padding: 5.5px 52px;
                        border-radius: 5px;
                    }
                }

                p {
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 100%;

                    @media (max-width: 525px) {
                        font-size: 12px;
                    }
                }
            }

            .radioBlock {
                @media (max-width: 1050px) {
                    align-items: center;
                }
            }

            .radioInputContent {
                display: flex;
                gap: 42px;

                .radioInputBlock {
                    display: flex;
                    align-items: center;
                    gap: 22px;

                    p {
                        font-size: 24px;
                        font-weight: 400;
                        line-height: 100%;

                        @media (max-width: 525px) {
                            font-size: 12px;
                        }
                    }

                    .customRadio {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 24px;
                        height: 24px;
                        border: 4px solid $base-color;
                        border-radius: 50%;

                        @media (max-width: 525px) {
                            width: 21px;
                            height: 21px;
                            border: 3px solid $base-color;

                        }

                        input[type=radio] {
                            appearance: none;
                            width: 12px;
                            height: 12px;
                            accent-color: $base-color;
                            border-radius: 50%;

                            @media (max-width: 525px) {
                                width: 11px;
                                height: 11px;

                            }
                        }

                        input[type=radio]:checked {
                            background-color: $base-color;
                            border-radius: 50%;
                            box-shadow: inset 0 0 14px -8px #000000;
                        }
                    }
                }
            }
        }
    }

    .callUs {
        position: relative;
        width: 318px;
        height: 459px;
        background-color: $base-color;
        background-image: url('../../assets/images/WeTransport/callUsBackground.svg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        @media (max-width: 1050px) {
            width: 100%;
            height: 250px;
        }

        @media (max-width: 525px) {
            width: 100%;
            height: 138px;
        }


        .content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            @media (max-width: 1050px) {
                width: 80%;
            }

            @media (max-width: 525px) {
                width: 306px;
            }

            .description {
                font-size: 24px;
                font-weight: 400;
                line-height: 130%;
                letter-spacing: 1.2px;

                @media (max-width: 1050px) {
                    font-size: 20px;
                }

                @media (max-width: 525px) {
                    font-size: 12px;
                    font-style: normal;
                    line-height: 130%;
                    letter-spacing: 0.6px;
                }
            }

            .orCall {
                color: $white;
                font-size: 32px;
                font-weight: bold;
                line-height: 175%;
                white-space: nowrap;

                @media (max-width: 1050px) {
                    font-size: 23px;
                }

                @media (max-width: 525px) {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 175%;
                    text-transform: lowercase;
                }
            }

            .callNumber {
                color: $white;
                font-size: 32px;
                font-weight: bold;
                line-height: 175%;
                white-space: nowrap;

                @media (max-width: 1050px) {
                    font-size: 23px;
                }

                @media (max-width: 525px) {
                    font-size: 16px;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 145.5%;
                }

            }
        }
    }

    .confirmMobile {
        display: none;

        @media (max-width: 1050px) {

            border-radius: 9px;
            background: #FF5408;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            color: $white;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 209px;
            height: 58px;
            gap: 10px;
            font-size: 24px;
            font-weight: 400;
            line-height: 100%;
            letter-spacing: 1.2px;
            cursor: pointer;
            transition: .3s;

            &:hover {
                box-shadow: 0px 0px 15px 2px #FF540877, inset 0px 0px 20px 3px rgba(0, 0, 0, 0.25);
            }
        }

        @media (max-width: 525px) {
            width: 90px;
            height: 25px;
            border-radius: 5px;
            font-size: 12px;
            letter-spacing: 0.6px;
            margin-top: 12px;
            margin-bottom: 7px;
        }
    }

    .confirm {
        border-radius: 9px;
        background: #FF5408;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        color: $white;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 209px;
        height: 58px;
        gap: 10px;
        font-size: 24px;
        font-weight: 400;
        line-height: 100%;
        letter-spacing: 1.2px;
        cursor: pointer;
        transition: .3s;

        &:hover {
            box-shadow: 0px 0px 15px 2px #FF540877, inset 0px 0px 20px 3px rgba(0, 0, 0, 0.25);
        }

        @media (max-width: 1050px) {
            display: none;
        }
    }


}