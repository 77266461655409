@import '../../assets/variables.scss';

.help {
    width: 100%;
    position: relative;
    .helpBackground {
        width: 100%;
        height: 665px;
        object-fit: cover;
   
        @media (max-width: 1600px) {
            height: 565px;   
        }
        @media (max-width: 1050px) {
            height: 400px;
        }

        @media (max-width: 525px) {
            height: 229px;
            object-fit: cover;
        }
    }

    .helpContent {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        gap: 133px;

        @media (max-width: 1600px) {
            transform: translate(-50%, -50%) scale(0.85);
        }

        @media (max-width: 1050px) {
            transform: translate(-50%, -50%) scale(1);
            gap: 45px;
        }

        @media (max-width: 525px) {
            gap: 24px;
        }

        .helpTitle {
            color: $white;
            font-size: 40px;
            font-weight: bold;
            h3 {
                font-family: $second-font;
                margin-bottom: 51px;
                font-weight: 700;

                @media (max-width: 1050px) {
                    font-size: 25px;
                    margin-bottom: 18px;
                }

                @media (max-width: 525px) {
                    font-size: 20px;
                }
            }

            .helpChild {
                font-weight: 400;
                font-size: 24px;
                line-height: 158%;

                @media (max-width: 1050px) {
                    font-size: 13px;
                }

                @media (max-width: 525px) {
                    font-size: 8px;
                }
            }
        }
    }
}