.TermsPage {
	margin: 50px auto;
	margin-top: 150px;
	max-width: 1230px;

	h3 {
		margin-top: 30px;
	}

	ul,
	ol {
		list-style-position: inside;
	}

	ul li,
	ol li {
		margin-top: 5px;
	}

	@media (max-width: 1230px) {
		margin: 1rem;
		margin-top: 3rem;
	}
}