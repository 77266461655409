@import '../../assets/variables.scss';

.footer {
  width: 100%;
  margin-top: 60px;

  @media (max-width: 736px) {
    margin-top: unset;
  }

  .contactInformationContent {
    position: relative;
    width: 100%;
    background-image: url("../../assets/images/Footer/footerBackground.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    padding-bottom: 44px;

    @media (max-width: 1600px) {
      transform: scale(0.85);
      padding: 37px 0;
    }

    @media (max-width: 1050px) {
      transform: unset;
    }

    .contactInformation {
      display: flex;
      justify-content: center;
      gap: 111px;

      @media (max-width: 1050px) {
        flex-direction: column;
        gap: 20px;
      }

      .infoBlock {
        display: flex;
        align-items: flex-end;
        gap: 22px;

        @media (max-width: 1050px) {
          align-items: center;
          justify-content: center;
        }

        @media (max-width: 525px) {
          gap: 6px;
        }

        img {
          @media (max-width: 670px) {
            width: 35px;
          }

          @media (max-width: 525px) {
            width: 20px;
          }
        }

        p {
          text-align: center;
          font-size: 26px;
          font-weight: 400;
          line-height: 120%;

          @media (max-width: 670px) {
            font-size: 20px;
          }

          @media (max-width: 525px) {
            font-size: 13px;
          }
        }
      }

    }

    .line {
      width: 50%;
      height: 3px;
      background-color: $light-text;
      margin: 47px auto 23px;
    }

    .subtitle {
      >h3 {
        font-family: "Agency FB Light";
        text-align: center;
        font-size: 54px;
        font-weight: 400;
        margin-bottom: 0;
      }

      >h2 {
        font-family: $second-font;
        text-align: center;
        font-size: 54px;
        font-weight: bold;
        line-height: 137.5%;
        margin-top: 0;

        @media (max-width: 525px) {
          font-size: 25px;
        }
      }

      >h4 {
        text-align: center;
        font-family: "Agency FB Light";
        font-size: 54px;
        font-weight: bold;
        line-height: 137.5%;

        @media (max-width: 525px) {
          font-size: 25px;
        }
      }
    }
  }

  .mainFooter {
    display: flex;
    position: relative;
    width: 100%;
    background-color: $base-color;
    padding-bottom: 44px;

    @media (max-width: 1600px) {
      height: 481px;
    }

    @media (max-width: 1050px) {
      height: unset;
    }

    @media (max-width: 525px) {
      padding-bottom: 20px;
    }

    .footerContent {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      margin: 0 auto;
      width: 80%;

      @media (max-width: 1600px) {
        width: 100%;
        transform: scale(0.85) translateY(-20px);
      }

      @media (max-width: 1050px) {
        transform: unset;
        width: 90%;

      }

      .footerBlock1 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (max-width: 1050px) {
          gap: 25px;
        }

        .imgBlock {
          >img {
            margin-top: 52px;

            @media (max-width: 550px) {
              margin-top: 17px;
              width: 52px;
            }
          }
        }



        .links {
          display: flex;
          justify-content: space-between;
          color: $white;

          @media (max-width: 1050px) {
            flex-direction: column-reverse;
          }

          .aboutUsBlock {
            width: 332px;
            margin-top: 48px;
            margin-right: 40px;

            @media (max-width: 1050px) {
              margin-top: -5px;
            }

            @media (max-width: 1050px) {
              width: unset;

            }

            .title {
              color: $white;
              font-size: 24px;
              font-weight: 500;
              line-height: normal;

              @media (max-width: 550px) {
                font-size: 12px;
              }
            }

            .description {
              color: $white;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 144%;

              @media (max-width: 550px) {
                font-size: 7px;
              }
            }
          }

          .SLBlock {
            display: flex;
            gap: 136px;
            margin-right: 14%;

            @media (max-width: 1165px) {
              margin-right: unset;
            }

            @media (max-width: 1050px) {
              justify-content: space-between;
              gap: 50px;
            }

            @media (max-width: 670px) {
              gap: 30px;
            }

            @media (max-width: 550px) {
              justify-content: unset;
              gap: 10px;
            }

            .solutions {
              >h4 {
                font-size: 20px;
                font-weight: bold;
                line-height: 100%;
                margin-bottom: 20px;

                @media (max-width: 550px) {
                  font-size: 10px;
                }
              }

              .solutionsContent {
                display: flex;
                flex-direction: column;
                gap: 12px;

                @media (max-width: 1050px) {
                  flex-direction: row;
                  gap: 50px;

                }

                @media (max-width: 670px) {
                  gap: 30px;
                }

                @media (max-width: 410px) {
                  gap: 0;
                }

                .subBlock {
                  &:nth-child(2) {

                    @media (max-width: 1100px) {
                      margin-top: .2rem;
                    }
                  }

                  >h5 {
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: bold;
                    line-height: 100%;
                    margin-bottom: 7px;

                    @media (max-width: 550px) {
                      margin-bottom: 9px;
                      margin-right: 18px;
                      font-size: 8px;
                    }

                    @media (max-width: 410px) {
                      margin-right: 10px;

                    }
                  }

                  >h4 {
                    @media (max-width: 550px) {
                      margin-bottom: 9px;
                      margin-right: 18px;
                      font-size: 8px;
                    }
                  }

                  >p {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 160%;

                    @media (max-width: 550px) {
                      margin-bottom: 9px;
                      margin-right: 18px;
                      font-size: 8px;
                    }
                  }
                }
              }
            }

            .loose {
              display: flex;
              flex-direction: column;
              flex-wrap: wrap;

              >h4 {
                font-size: 20px;
                font-weight: bold;
                line-height: 100%;
                margin-bottom: 20px;

                @media (max-width: 550px) {
                  font-size: 10px;
                }
              }

              .looseContent {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;

                @media (max-width: 550px) {
                  height: 80px;
                }

                >p {
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 160%;

                  @media (max-width: 550px) {
                    margin-bottom: 9px;
                    margin-right: 18px;
                    font-size: 8px;
                  }
                }

                .link {
                  font-size: 16px;
                  font-weight: 400;
                  color: #ffffff;
                  text-decoration: none;

                  &:hover {
                    text-decoration: underline;

                  }

                  @media (max-width: 550px) {
                    margin-bottom: 9px;
                    margin-right: 18px;
                    font-size: 8px;
                  }
                }
              }
            }
          }

        }
      }

      .footerBlock2 {
        display: flex;
        flex-direction: column;

        .social {
          display: flex;
          flex-direction: column;
          gap: 1em;
          width: 100%;

          .messages {
            display: flex;
            justify-content: flex-end;

            img {
              @media (max-width: 550px) {
                width: 32px;
              }
            }
          }

          .LSBlock {
            display: flex;
            justify-content: space-between;

            .socialMediaBlock {
              display: flex;
              align-items: center;
              gap: 28px;

              @media (max-width: 550px) {
                gap: 24px;
              }

              img {
                @media (max-width: 550px) {
                  width: 19px;
                }
              }
            }

            .license {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;

              @media (max-width: 550px) {
                font-size: 7px;
              }
            }
          }
        }
      }

      .line {
        height: 2px;
        width: 100%;
        opacity: 0.2;
        background: #000;
      }
    }
  }
}