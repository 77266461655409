// colors
$base-color: #FF5408;
$base-color-light: #FF540888;
$light-gray: #D9D9D9;
$white: #ffffff;
$black: #000000;
$light-text: #787676;
$light-border: #e7e7e7;

// fonts
$base-font: 'Poppins';
$second-font: 'Agency FB';