@import '../../assets/variables.scss';

.carousel {
    .slideBlock {
        position: relative;

        img {
            width: 100%;
            min-height: 200px;
            object-fit: cover;
            @media (max-width: 1600px) {
                height: 462px;
            }
    
            @media (max-width: 1200px) {
                height: unset;

            }
        }

        .content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            @media (max-width: 1600px) {
                transform: translate(-50%, -50%) scale(0.85);
            }

            @media (max-width: 1200px) {
                transform: translate(-50%, -50%);
            }

            .title {
                color: #FFF;
                text-align: center;
                font-family: $second-font;
                font-size: 40px;
                font-weight: bold;
                line-height: 144%;

                @media (max-width: 1200px) {
                    font-size: 35px;
                }

                @media (max-width: 1000px) {
                    font-size: 30px;
                }

                @media (max-width: 670px) {
                    font-size: 20px;
                }
            }

            .description {
                color: #FFF;
                font-size: 24px;
                font-weight: 400;
                line-height: 190%;
                text-align: center;

                @media (max-width: 1200px) {
                    font-size: 20px;
                }

                @media (max-width: 1000px) {
                    font-size: 16px;
                }

                @media (max-width: 800px) {
                    font-size: 14px;
                }

                @media (max-width: 670px) {
                    font-size: 10px;
                }
            }
        }
    }

    .carousel-arrow.block.carousel-prev {
        @media (max-width: 1600px) {
            transform: scale(0.85);
        }

        @media (max-width: 1200px) {
            transform: unset;
        }

        &:before {
            content: '';
            width: 37px;
            height: 78px;
            background-image: url('../../assets/images/Footer/sliderArrowLeft.png');
            transform: translateX(233px);

            @media (max-width: 1200px) {
                width: 30px;
                height: 65px;
                transform: translateX(10%);
            }

            @media (max-width: 670px) {
                width: 8px;
                height: 16px;
            }
        }
    }

    .carousel-arrow.block.carousel-next {
        @media (max-width: 1600px) {
            transform: scale(0.85);
        }

        @media (max-width: 1200px) {
            transform: unset;
        }

        &:before {
            content: '';
            width: 37px;
            height: 78px;
            background-image: url('../../assets/images/Footer/sliderArrowRight.png');
            transform: translateX(-233px);

            @media (max-width: 1200px) {
                width: 30px;
                height: 65px;
                transform: translateX(-10%);
            }

            @media (max-width: 670px) {
                width: 8px;
                height: 16px;
            }
        }
    }
}