.home {
    position: relative;
    display: flex;
    justify-content: center;
    background-image: url('../../assets/images/Home/homeBackground.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 1660px;
    @media (max-width: 1600px) {

        background-size: 100% 100%;
        height: 1300px;
    }
    @media (max-width: 1400px) {
        height: 1200px;
    }
    @media (max-width: 1300px) {
        background-size: 100% 100%;
        height: 1100px;
    }
    
    @media (max-width: 1200px) {
        background-size: cover;

    }

    @media (max-width: 1100px) {
        height: 900px;
    }

    @media (max-width: 525px) {
        height: 442px;
        background-image: url('../../assets/images/Home/homeBackgroundMobile.png');
    }
}