@import '../../assets/variables.scss';

.weTransport {
    width: 100%;
    position: relative;
    background-image: url("../../assets/images/WeTransport/weTransportBackground.png");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: contain;
    padding-bottom: 30px;

    @media (max-width: 1050px) {
        background-image: unset;
        border-bottom: 2px solid $base-color;
    }

    .howDoIt {
        position: relative;
        width: max-content;
        margin: 0 auto;

        @media (max-width: 1600px) {
            transform: scale(0.85) translateY(-52px);
        }

        @media (max-width: 1050px) {
            transform: unset;
            margin: 25px auto 0;

        }

        div {
            position: relative;
            width: max-content;

            >h3 {
                font-family: $second-font;
                font-size: 36px;
                font-weight: bold;
                line-height: 100%;
                margin-bottom: 34px;

                @media (max-width: 1050px) {
                    font-size: 25px;
                }

                @media (max-width: 525px) {
                    font-size: 18px;
                    margin-bottom: 10px;
                }
            }

            .arrow1 {
                position: absolute;
                top: 40%;
                left: 130%;

                @media (max-width: 525px) {
                    width: 30px;
                    left: 115%;
                }
            }

            .arrow2 {
                position: absolute;
                top: 40%;
                left: 110%;

                @media (max-width: 525px) {
                    width: 15px;
                    left: 110%;
                }
            }

            .arrow3 {
                position: absolute;
                top: 40%;
                left: 75%;

                @media (max-width: 1050px) {
                    left: 62%;
                }

                @media (max-width: 525px) {
                    width: 72px;
                    left: 80%;
                }
            }
        }

        p {
            color: $light-text;
            font-size: 20px;
            font-weight: 400;
            line-height: 120.5%;
            margin-bottom: 30px;

            @media (max-width: 1050px) {
                font-size: 14px;
            }

            @media (max-width: 525px) {
                font-size: 9px;
                margin-bottom: 10px;
            }
        }
    }

    .line {
        height: 2px;
        background-color: $base-color;
        width: 100%;
        margin: 0 auto;
        transform: translateY(75px);
    }

    .comingSoon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: "Agency FB";
        color: $black;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(15px);
        z-index: 1;
        h2 {
            font-size: 50px;
        }
    }
}