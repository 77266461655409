@import '../../assets/variables.scss';

.guideItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 325px;

    @media (max-width: 1050px) {
        align-items: center;
        width: unset;
        flex-direction: row;
        gap: 40px;
    }

    .imageBlock {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 142px;
        height: 149px;
        overflow: hidden;

        @media (max-width: 1050px) {
            width: 120px;
            height: unset;
        }

        @media (max-width: 525px) {
            width: 70px;
        }

        img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            transition: .3s;

            @media (max-width: 1050px) {
                position: unset;
            }
        }

        .imageHover {
            width: 96%;
            height: 143px;
            opacity: 0;
            position: absolute;
            transform: translateX(3px);
            transition: .3s;
            top: 0;
            left: 0;
            border-radius: 19px;
            background: linear-gradient(transparent, $base-color-light);
            border-top: 4px solid #adadade0;

            @media (max-width: 1050px) {
                border-top: 2px solid #adadade0;
                border-radius: 10px;
                left: -2px;
                height: 96%;
                width: 97%;
            }
        }

        &:hover {
            .imageHover {
                opacity: 1;

            }
        }
    }

    .descriptionBlock {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 1050px) {
            width: 280px;
        }

        @media (max-width: 525px) {
            width: 200px;
        }

        h4 {
            margin-top: 28px;
            margin-bottom: 48px;
            font-family: Agency FB;
            font-size: 40px;
            font-weight: bold;

            @media (max-width: 1050px) {
                font-size: 30px;
                margin-top: unset;
                margin-bottom: 5px;
            }

            @media (max-width: 525px) {
                font-size: 22px;
            }
        }

        p {
            color: $light-text;
            font-size: 24px;
            font-weight: 400;
            text-align: center;

            @media (max-width: 1050px) {
                font-size: 16px;
            }

            @media (max-width: 525px) {
                font-size: 11px;
            }
        }
    }
}